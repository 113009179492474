@import './variables.scss';
@import url('http://fonts.cdnfonts.com/css/tt-commons');

@for $i from 1 through 6 {
    h#{$i} {
        font-size: 16px + 8 * (6 - $i);
        color: $text-color;
    }
}

.section-title {
    color: $text-color;
    max-width: 500px;

    &::before {
        content: '';
        display: block;
        margin-bottom: 23px;
        height: 5px;
        width: 70px;
        border-radius: 0 20px 20px 0;
        background-color: #fe4370;
    }
}

a, button {
    &:hover {
        cursor: pointer;
    }
}
a {
    color: $accent;
}
.accent-highlight {
    color: $accent;
}

.overline {
    height: 5px;
    width: 70px;
    border-radius: 0 20px 0 0;
    background-color: $accent;
}

.paragraph {
    max-width: 590px;
    color: $text-color;
    padding-bottom: 20px;
}

body {
    margin: 0 auto;
    font-family: 'TT Commons', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $background;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

p {
    color: $text-color;
    padding-bottom: 20px;
}

button {
    border: none;
}

.loader:empty {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
  }
  
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

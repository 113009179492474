@import '../../variables.scss';

.hero {
    padding: 110px 40px;
}

.maxWidth {
    max-width: max-content !important;
}

.background {
    background-color: $background-alt;
}

.section-content {
    max-width: 1280px;
    margin-left: calc(50% - 640px);
}

.spacing {
    padding: 80px 40px ;
}

.onlyTitle {
    padding: 40px;
    & h2 {
        margin-bottom: 0;
    }
}

.noPadding {
    padding: 0 0 90px 0;
}


@media screen and (max-width: 1390px) {
    .section-content {
        margin-left: 0%;
    }

    .hero {
        padding: 90px 40px;
    }

    .paddingRight {
        padding-right: 0;
    }

    .noPadding {
        padding-left: 40px;
        padding-right: 20px;
    }
}
@import '../../variables.scss';

.slider {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    // max-width: 1660px;

    &::-webkit-scrollbar {
        height: 25px;
    }
    
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px grey;
        border-top: solid 15px transparent;
        border-bottom: solid 3px transparent;
        border-left: solid 1px transparent;
        border-right: solid 20px transparent;
  
    }
    
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px $accent;
        border-top: solid 15px transparent;
        border-bottom: solid 3px transparent;
        border-left: solid 1px transparent;
        border-right: solid 20px transparent;
    }
    
    .element {
        border-radius: 14px;
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        flex-shrink: 0;
        margin-right: 10px;

        & img {
            align-self: center;
        }
        & div {
            justify-self: flex-end;
            position: absolute;
            bottom: 3.4%;
            color: #FFF;
            font-size: 24px;
            font-weight: 300;
          }
    }
}

.__react {
    width: 300px;
    background: #1B1E32;
}
.__laravel {
    width: 240px;
    background: #1B1E32;
}
.__sass {
    width: 190px;
    background: #D56FA4;
}
.__figma {
    width: 280;
    background: #1E1E1E;
}
.__adobe {
    width: 190px;
    background: #1B45A6;
    background-image: url('./Assets/adobe-gradient.png');
    background-position: center center;
    background-size: cover;
}
.__js {
    width: 280px;
    background: #F7DF1E;
}
.__html {
    width: 240px;
    background: #DA7448;
}
.__css {
    width: 280px;
    background: #1B45A6;
}


@media screen and (max-width: 475px) {


}
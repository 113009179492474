@import '../../../variables.scss';

.footer {
    padding: 30px;
    background-color: $accent;
    display: flex;
    height: 95px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    & div, a {
        color: #FFF;
        font-weight: 200;
        font-size: 20px;
    }
    & img {
        padding-top: 20px;
    }
    
}
@import '../../variables.scss';

.flex {
    display: flex;
    flex-direction: row;
}
.slider {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    max-width: 1660px;

    &::-webkit-scrollbar {
        height: 25px;
    }
    
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px grey;
        border-top: solid 15px transparent;
        border-bottom: solid 3px transparent;
        border-left: solid 1px transparent;
        border-right: solid 20px transparent;
  
    }
    
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px $accent;
        border-top: solid 15px transparent;
        border-bottom: solid 3px transparent;
        border-left: solid 1px transparent;
        border-right: solid 20px transparent;
    }

    &__element {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        height: 260px;
        width: 360px;
        border-radius: 14px;
        border: 4px solid #B8B8B8;
        opacity: 1;
        flex-shrink: 0;
        transition: all 0.6s ease-in-out;

        &--current {
            height: 360px;
            width: 460px;
            border: 4px solid $accent;
        }

        &__date {
            display: flex;
            align-items: center;
            width: calc(360px + 13px);
            flex-shrink: 0;
            padding-left: 10px;
            color: $accent;
            font-weight: 600;
            font-size: 22px;
            transition: all 0.6s ease-in-out;
            
            &--current {
                width: calc(460px + 13px);
            }
            &__wrapper {
                display: flex;
                flex-direction: row;
                padding:20px 0;
            }
            &__line {
                margin-left: 5px;
                height: 1px;
                width: 100%;
                background-color: #B8B8B8;
            }
        }
    }
}

@media screen and (max-width: 475px) {
    .shapes__container__three {
        opacity: 0;
    }

    .slider__element {
        width: 300px;
        height: 210px;
        &--current {
            width: 300px;
            height: 270px;
        }
    }

    .slider__element__date {
        width: 300px;
        &--current {
            width: 290px;
        }
    }

}